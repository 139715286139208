<template>
  <section class="container max-w-screen-lg py-6 sm:py-0 pb-4 px-4 xl:px-0 mb-8 text-center mt-24">
    <img src="./../assets/img/icons/leuchtturm.svg" class="mx-auto" alt="">
    <h2 class="h1 mt-4">Unsere Matrosen</h2>
    <p class="w-full sm:max-w-3xl mx-auto text-left sm:text-center">Wir stellen unsere Crew vor, einen Zusammenschluss leidenschaftlicher Softwareentwickler. Wir verstehen uns als Techies im Herzen, die gemeinsam etwas bewegen werden.</p>
    <div class="grid grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6 md:gap-8 mt-16 mb-32">
      <div class="relative lg:max-w-max" v-for="(person, i) in shuffledPersons" :key="person.name">
        <div class="square" @mouseover="overItem(i)" @mouseleave="leaveItem()">
          <div :class="'team-jobs absolute left-0 right-0 overflow-hidden text-left shadow-md pl-4 border border-gray-300 rounded-b-md z-10 bg-white transition-all ' + (highlight == i ? 'h-22' : 'h-0')">
            <span :class="'block pt-4 transition-all ' + (highlight == i ? '' : '')">{{person.name}}</span>
            <span class="block transition-all pb-3" style="color: #0183C1;">{{person.position}}</span>
          </div>
          <div :class="'overflow-hidden rounded-lg shadow-md ' + (highlight == i ? 'relative z-20' : '')">
            <img :src="'team/' + person.image" :alt="person.name + ' | Softwerkstatt'" class="transform scale-103 transition-all" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Team',
  data: () => {
    return {
      highlight: -1,
      persons: [
        {
          name: 'Dario D. Müller',
          position: '.NET Entwicklung',
          image: 'dario.webp'
        },
        {
          name: 'Ioannis Michail',
          position: '.NET Entwicklung',
          image: 'ioannis.webp'
        },
        {
          name: 'Lasse Klüver',
          position: '.NET Entwicklung',
          image: 'lasse.webp'
        },
        {
          name: 'Renke Haverich',
          position: 'Strategie',
          image: 'renke.webp'
        },
        {
          name: 'Stefan Röhrnbeck',
          position: 'Talent Acquisition',
          image: 'stefan.webp'
        },
        {
          name: 'Jakub Stanosz',
          position: 'Frontend Entwicklung',
          image: 'jakub.webp'
        },
      ],
    }
  },
  computed: {
    shuffledPersons: function () {
      var x = this.shuffle(this.persons);
      var darioIndex =this.persons.findIndex(p => p.name === 'Dario D. Müller');
      var dario = x.splice(darioIndex, 1);
      x.unshift(dario[0]);
      return x;
    }
  },
  methods: {
    overItem(index) {
      this.highlight = index;
    },
    leaveItem() {
      this.highlight = -1;
    },
    shuffle(a) {
      var j, x, i;
      for (i = a.length - 1; i > 0; i--) {
          j = Math.floor(Math.random() * (i + 1));
          x = a[i];
          a[i] = a[j];
          a[j] = x;
      }
      return a;
    }
  }
}
</script>

<style scoped>
p, ul {
  margin-top: 1.0rem;
}
.square {
  margin-top: 100%;
  position: relative;
}
.square div {
  margin-top: -100%;
}
.square div.team-jobs {
  bottom: 0rem;
}
.square:hover div.team-jobs {
  bottom: -5.0rem;
}
.square img {
  filter: grayscale(0.1);
}
.square:hover img {
  filter: brightness(1.1) grayscale(0);
}
</style>